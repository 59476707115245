import React from 'react';
import { graphql } from 'gatsby';

import { Article } from '../organisms/article';
import Layout from '../organisms/layout';
import TitledCard from '../atoms/titled-card';
import { TableOfContents } from '../organisms/table-of-contents';
import { SnsShare } from '../molecules/sns-share';
import TagCloud from '../organisms/tag-cloud';
import { PostTemplateQuery } from '../../../types/graphql-types';
import PathUtils from '../../utils/path-utils';
import Adsense from '../molecules/adsense';

export const query = graphql`
  query PostTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;

const PostTemplate = (props: { data: PostTemplateQuery }) => {
  const post = props.data.markdownRemark!;
  return (
    <Layout
      asides={[
        // <TitledCard title="人気の記事">
        //   準備中
        // </TitledCard>
        // ,
        <TitledCard title="タグクラウド" key="tag-cloud">
          <TagCloud />
        </TitledCard>,
      ]}
      asidesSticky={[
        <TitledCard title="目次" key="Table-of-contents">
          <TableOfContents slug={post.fields?.slug ?? ''} />
        </TitledCard>,
        // <TitledCard title="広告など">
        //   準備中
        // </TitledCard>,

        <SnsShare
          slug={PathUtils.slugToDateName(post.fields?.slug ?? '').name}
          title={post.frontmatter?.title ?? ''}
          excerpt={post?.excerpt ?? ''}
          key="sns"
        />,
        <Adsense />,
      ]}
    >
      <Article slug={post.fields?.slug ?? ''} />
    </Layout>
  );
};
export default PostTemplate;
